
import CyanPageWrapper, {
  defineComponent,
  IonPage,
  store
} from "@/components/CyanPageWrapper.vue";
import JuntaPicker from "@/components/JuntaPicker.vue";
import { territorios } from '@/modules/cyanRegions'
import unreact from "@/modules/unreact";
import cyanRegions from "@/modules/cyanRegions";

export default defineComponent({
  name: "DirPickJunta",
  components: {
    JuntaPicker,
    IonPage,
    CyanPageWrapper,
  },
  computed: {
    seed() {
      return unreact((this as any).$route.params.seedPJ+'') || ''
    }, 
    centro() {
      return unreact((this as any).$route.params.centroPJ+'') || '0'
    }, 

    ambito() {
      if (this.seed in territorios) {
        const m = territorios[(this as any).seed];
				return  m.nombre + ', ' + m.nombrePadre;
      }
      return '';
			
    },
    title() {
			if (this.ambito) {
				return 'Elegir junta (' + this.ambito + ')';
			}
			return 'Elegir junta';
		},
		userLevel() {
      return store.state.userData.nivel || '';
    },
		backRoute() {

      // Si hay un único centro disponible, volvemos al nivel municipal (o a home)

      const acj = store.state.allowedCentrosJuntas;

      if (acj && acj[this.seed] && acj[this.seed].centros && acj[this.seed].centros.length == 1) {
        return ((this as any).userLevel.length == cyanRegions.largoFinal || !cyanRegions.divisiones[cyanRegions.largoFinal].largoPadre) ? 
          '/home' : 
          '/dirPicker/' + (this.seed as any).substr(0,cyanRegions.divisiones[cyanRegions.largoFinal].largoPadre);  
      }

      // Else, volver al centro

      return '/dirPickCentro/' + this.seed;
		},

  },
  methods: {},
});
